import * as React from 'react';

import { MainSection, ReadyToStartSection } from 'components/HomeSections';
import MainLayout from 'layouts/MainLayout';

import { Box, Container } from '@mui/material';
import { graphql, PageProps } from 'gatsby';
import { GenericTemplate } from 'templates';
import { Advertisement } from 'components';
import { AnimatedContainer } from 'components/containers';
import { useOfferItems } from 'hooks';
import { sectionDarkStyle, sectionStyle } from 'utils';
import bgrLightSmall from 'images/bgr/bgr-light-small.inline.png';
import mainSectionImg from 'images/Pitch-meeting.svg';

type NodeProps = {
  locale: string;
  order: number;
  template: string;
  slug: string;
  key: string;
  sum: string;
  title: string;
  subtitle: string;
  items: {
    type: string;
    data: any[];
  };
  img?: { src: { publicURL: string }; darkBgr?: boolean };
  banner?: {
    text: string;
    contactButton: boolean;
    color: 'dark' | 'main';
  };
  darkBgr?: boolean;
};

const AboutUsPage = ({ data }: PageProps<any>) => {
  const items = useOfferItems();
  const offerItems = items;
  const metaTitle = data.allAboutUs.nodes.find((el: any) => el.key === 'about-us-section')?.metaTitle;
  const metaDescription = data.allAboutUs.nodes.find((el: any) => el.key === 'about-us-section')?.metaDescription;

  const setTemplate = (node: NodeProps) => {
    return (
      <React.Fragment key={node.title}>
        <Box sx={[{ my: { xs: 3, md: 4 } }, node.darkBgr ? sectionDarkStyle : sectionStyle]}>
          <Container maxWidth="lg" sx={{ position: 'relative' }}>
            <GenericTemplate data={node} />
          </Container>
        </Box>
        {node.banner && (
          <AnimatedContainer>
            <Box sx={{ mt: { xs: 3, md: 4 } }}>
              <Advertisement
                text={node.banner.text}
                contactButton={node.banner.contactButton}
                color={node.banner.color}
              />
            </Box>
          </AnimatedContainer>
        )}
      </React.Fragment>
    );
  };

  return (
    <MainLayout
      pageTitle="NextGen Software House"
      metaTitle={metaTitle}
      metaDescription={metaDescription}
      offerItems={offerItems}
    >
      <AnimatedContainer>
        <MainSection
          title="about-us-title"
          subtitle={{
            id: 'about-us-intro',
            values: {
              value1: <strong>Stop! Think! Then act!</strong>
            }
          }}
          caption="about-us-intro-more"
          icon={mainSectionImg}
        />
      </AnimatedContainer>
      {data.allAboutUs.nodes.map((el: any) => setTemplate(el))}

      <AnimatedContainer>
        <Box sx={{ background: `url(${bgrLightSmall}) no-repeat center center / cover` }}>
          <Container maxWidth="lg" sx={{ pt: 5, pb: 8 }}>
            <ReadyToStartSection title="ready-to-start-title" subtitle="ready-to-start-subtitle" />
          </Container>
        </Box>
      </AnimatedContainer>
    </MainLayout>
  );
};

export const query = graphql`
  query ($locale: String) {
    allAboutUs(filter: { locale: { eq: $locale } }, sort: { fields: order, order: ASC }) {
      nodes {
        id
        key
        order
        metaTitle
        metaDescription
        sum
        title
        subtitle
        template
        titleAlign
        items {
          type
          grid {
            xs
            md
          }
          data {
            key
            title
            text
            color
            icon {
              src {
                svg {
                  content
                }
              }
            }
          }
        }
        banner {
          text
          contactButton
          color
        }
        darkBgr
      }
    }
  }
`;

export default AboutUsPage;
